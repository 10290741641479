import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {
  "key": "reusable",
  "langKey": "en",
  "title": "general-hottub-life-1"
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`How to extend your hot tub beyond it's warrantee`}</h1>
    <p>{`Hot tubs are expenisive purchases. Many people don't use them as much as they could, and even if you don't use them, they wear out just 'sitting' there, especially if you are in a cold climate (that freezes) and your hot tub is outside.`}</p>
    <p>{`I have learned, the big ones I learned the hard way, how to reduce the cost of maintenance and repairs.`}</p>
    <h1>{`First some minor tips`}</h1>
    <p>{`Test your water chemistry periodically. If you get things out of wack, it can damage your pump, your heater and other things. There is lots about this written on the internet, so for now, I'm just going to say: do it!`}</p>
    <p>{`Use copper/silver for sterilzation instead of bleach/chlorine. Use 1/2 cup of bleach per short haired person per use and 1 cup of bleach per long haired person per use, after use, to collect the biologicals in a way the filter can filter them out. This way there is less bleach/chlorine damagaging things like the surfaces of the hot tub and probably more important, the cover.`}</p>
    <p>{`Use Walmart bleach, or other low cost household bleach, instead of expenive 'chlorine'. Here is the thing: Household bleach is made to MORE stringent standards than even profession pool chlorines - and it is the exact same chemical. So household bleach is a BETTER (and a lot cheaper) choice than pool bleach! My hot tub store admits this, but sells chorine for those people that WANT to pay more.`}</p>
    <h1>{`Now the major tips`}</h1>
    <p>{`I have seen that the 2 most common repairs are:`}</p>
    <ol>
      <li parentName="ol">{`A pipe bursts because it has been, for years, rubbing against another pipe. Now when I say burst, I really mean "it springs a small leak that, when the pump is on, sprays out water". But it sprays out a LOT of water in a day, and you have to both replace that water AND you have to heat the new water. But even worse ...`}</li>
    </ol>
    <ol {...{
      "start": 2
    }}>
      <li parentName="ol">{`All that water spraying around causes mold (yuck) under the hot tub. But even worse ...`}</li>
    </ol>
    <ol {...{
      "start": 3
    }}>
      <li parentName="ol">{`All that water spraying gets at a minimum high humidity and at the worst water on your pump and electronics, causing the pumps and electronics to fail years before they have to`}</li>
    </ol>
    <p>{`Now, you could fix one at a time as you notice them (you 1st notice them eitherl because of water leaking out around the hot tub or, if you are unluckly like me, because you start to notice that you need to add more and more water every day to keep the levels up.)`}</p>
    <p>{`Unfortunately, hot tubs are NOT easy to remove the panel and, it my area, insulation and tape that prevents air and moisture flow out to prevent freezing and heat loss. So you put it off for weeks until there is lots of damaage.`}</p>
    <p>{`What you will find is everywhere 2 pipes rub against each other, they are going to eventually spring a leak. In my most carefully watched case, half of them sprung a leak between 5 years and 8 years. (I still had a warrantee at 5 years, but they charged to send a technician to 'do' the warrantee work - so I accepted the free pump and connector and did the repair - very effectively - myself)`}</p>
    <p>{`Where I failed, is that I fixed "the" leak and pump. Then a year later "The" leak and pump. Then a year later "The TWO leaks" (fortunately no pump). Then a year later "The" leak and the OTHER pump and the control panel.`}</p>
    <p>{`At this point (sometimes I am a REALLY slow learner, just ask my wife) I finnally woke up and realized: I should do something about this rather than just repairing each leak as I find it. So the first thing I did was write this blog!`}</p>
    <p>{`My first thought was "This is a manufacturer DESIGN FLAW". But we currently have 2 hot tubs, one indoor (Dynasty Spa) and one outdoor (Polar Spas), from two manufacturers, and both have the same 'design flaw'.`}</p>
    <p>{`I guess the thought in the hot tub industry is: Our design will outlast the warrantee, and then when the pipes all start leaking, the customer will throw the hot tub awaiy and come back and buy another one from us. To be fair, I found the staff and owner at both of these stores to be really good, so I suspect that this problem seldom happens 'during warrantee' so they simply don't realize that they have this design flaw. I mentioned it to a gal at Dynasty spa, but I don't think it mentally registered as something that should be reported to managment.`}</p>
    <h1>{`Advise to self:`}</h1>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`Next time I buy a hot tub, don't wait for the warrantee to run out. Immmediately go in, put some protection between every pipe that touches each other so that they don't wear through 1 month after the warrantee runs out.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`If that turns out to not be sufficient, when I get the FIRST leak: Put a 'couple' at EVERY location that pipes touch each other (2 couple running against each other should last 10x's longer - 100 years compared to 2 soft pipes running against each other)`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Start miy own hot tub company and do it right. OK, I probably won't do this, while I build and sell companies, I do mostly B2B companies not B2C product companies.`}</p>
      </li>
    </ol>
    <p><img parentName="p" {...{
        "src": "/files/2020-09-05-hottub-leak1.png",
        "alt": "The front pipe has 2 pinholes (loses 50 gallons a day) and the back is worn partly. The black line shows me where to cut to fix it."
      }}></img>{`
`}<img parentName="p" {...{
        "src": "/files/2020-09-05-hottub-leak2.png",
        "alt": "The top pipe is going to soon start leaking"
      }}></img>{`
`}<img parentName="p" {...{
        "src": "/files/2020-09-05-hottub-leak3.png",
        "alt": "It is a combination of which is softer and which has thinner walls that will determine which of these two will leak first - the winner in my case? The thicker walled white is the 1st to leak."
      }}></img>{`
`}<img parentName="p" {...{
        "src": "/files/2020-09-05-pump1.png",
        "alt": "Replacing an expensive pump because the manufacturer had 2 pipes touching each other, that hurts"
      }}></img></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      